export const INTERNAL_LINKS = {
  HOME: '/',
  ABOUT: '/about',
  JOIN: '/join-us',
};

export const EXTERNAL_LINKS = {
  DH_STATS: 'https://stats.dubhacks.co/',
  DH_NEXT: 'https://next.dubhacks.co/',
  DH_FB: 'https://www.facebook.com/DubHacks.Co/',
  DH_LINKEDIN: 'https://www.linkedin.com/company/dubhacks/',
  DH_INSTAGRAM: 'https://www.instagram.com/dubhacks_/',
  DH_EMAIL: 'mailto:hello@dubhacks.co',
  MLH_CODE_OF_CONDUCT: 'https://static.mlh.io/docs/mlh-code-of-conduct.pdf',
  DH_TEAM_24: 'https://dubhacks.notion.site/DubHacks-Team-2024-Hiring-5c92342e87124d08a2bf0e352147eb3a',
  DH_24: 'https://dh24.dubhacks.co',
  DH_24_INTEREST:
    'https://docs.google.com/forms/d/e/1FAIpQLSe0NfEWEdliX_pqXy1me6N5iYPU9_5X_vZLaVB1SM3nD9ACzw/viewform?usp=sf_link',
  DH_24_MENTOR_SIGN_UP: 'https://forms.gle/ACQWwdS3d6uR3R8Q6',
  DH_24_APPLY: 'https://forms.gle/p66jmduSJbkX8Ncu9',
  DH_TEAM_25_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSfiSqCxvOf5__8MtNtHqtXbbyByMzFBSMVawc6SiEvyig8e3Q/viewform?usp=sf_link',
};
