import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const DesignTeamCard = () => {
  return (
    <Card>
      <SubTitle>🎨 Design</SubTitle>
      <Text>
        As a Designer for DubHacks, you will create the DH brand and design visual assets for our web and mobile
        platforms, social media accounts, promotional items, and more for our hackathon. A successful designer will work
        well on a cross-functional team and is excited about building a brand identity that embodies our mission. Strong
        applicants will have experience with brand, visual, or UI/UX design, but aren't expected to have worked with all
        of them. <b>Design team applicants must submit a portfolio.</b>
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>
            Establishing the visual direction for DubHacks 2025 (fonts, inspirations, style guides) and defining a
            visual brand for DH events, programs, & social media platforms.
          </ListItem>
          <ListItem>
            Designing web-optimized, high-quality visual assets for the DubHacks 2025 brand guide and prototyping
            website layout in Figma.
          </ListItem>
          <ListItem>
            Collaborating with Tech Team on prototyping responsive website & mobile layout for DubHacks (website, Hacker
            Profile, etc.)
          </ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>Figma, Adobe Illustrator, & best practices in printing.</ListItem>
          <ListItem>Experience with visual or brand designs, illustration, and composition.</ListItem>
          <ListItem>
            A technical understanding of UI/UX or interaction design and interest in system thinking and information
            architecture.
          </ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>
            Designed DubHacks Hackathon website, branding materials, merchandise, and developed the DH identity targeted
            to the tech & entrepreneurial community at UW.
          </ListItem>
          <ListItem>
            Improved the experience for 1,100+ hackers through collaboration with engineers and stakeholders with
            measurable metrics.
          </ListItem>
          <ListItem>
            Gained experience in visual design, branding, cross-functional collaboration, sponsor & technical
            communication, and technical implementation.
          </ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
